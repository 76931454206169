import React from 'react'
import styles from './styles.module.css'

const Loader = () => (
  <div className={styles.container}>
    <svg
      aria-hidden="true"
      className={styles.loader}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle className={styles.circle} cx="50" cy="50" r="40" />
    </svg>
  </div>
)

export default Loader
