import checkMarkCircle from './icons/CheckMarkCircle'
import alert from './icons/Alert'

const icons = {
  checkMarkCircle: checkMarkCircle,
  alert: alert,
}

// TODO: Create mapped type
type Props = {
  size?: number
  fill?: string
}

type IconName = keyof typeof icons

//export const iconNames = Object.keys(icons)

const Icon = (
  props: Props & {
    [P in IconName]?: true
  }
) => {
  const key = Object.keys(props).find((key) => icons[key as IconName])
  if (!key) throw new Error('Icon not found')
  return icons[key as IconName](props)
}

export default Icon
