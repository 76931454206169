import styles from './Modal.module.scss'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode | ReactNode[]
}

const Modal: React.FC<Props> = ({ children }) => {
  return <div className={styles.modal}>{children}</div>
}

export default Modal
