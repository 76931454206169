import styles from './Heading.module.css'
import React, { ReactNode } from 'react'

interface Props {
  level: '1' | '2' | '3' | '4' | '5' | '6'
  children: ReactNode | ReactNode[]
  align?: 'left' | 'center' | 'right'
  style?: React.CSSProperties
}

const Heading: React.FC<Props> = ({
  //
  level,
  style,
  children,
  align = 'center',
}) => {
  const H = `h${level}`
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <H className={styles.heading} style={{ ...style, textAlign: align }}>
      {children}
    </H>
  )
}

export default Heading
