import React from 'react'

const CheckMarkCircle: React.FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#4AB101" fillOpacity="0.15" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#4AB101"
      d="M23.8888 10C31.5546 10 37.7779 16.2233 37.7779 23.8888C37.7779
        31.5546 31.5546 37.7779 23.8888 37.7779C16.2233 37.7779 10 31.5546 10
        23.8888C10 16.2233 16.2233 10 23.8888 10Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="white"
      d="M22.8409 25.5149L19.2543 22.7257C19.1334 22.6316 18.9593 22.6537
      18.8655 22.7741L17.5038 24.5249C17.4101 24.6457 17.4318 24.8199 17.5526
      24.9141L23.1088 29.2349C23.1897 29.2974 23.2943 29.3087 23.383
      29.2728L23.3855 29.272C23.4284 29.2541 23.4672 29.2253 23.4976
      29.1862L30.5076 20.172C30.6018 20.0512 30.5801 19.877 30.4593
      19.7832L28.7084 18.4216C28.5876 18.3278 28.4134 18.3495 28.3193
      18.4703L22.8409 25.5149Z"
    />
  </svg>
)

export default CheckMarkCircle
