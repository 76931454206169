import { Auth0Provider } from '@auth0/auth0-react'
import { useEffect, useState } from 'react'
import './App.css'
import Footer from './components/Footer'
import Loader from './components/Loader'
import Main from './components/Main'

function App() {
  const [connection, setConnection] = useState('')

  useEffect(() => {
    // Read connection from querystring or sessioStorage when entering the page
    const defaultConnection = 'Almatunnus-Business'
    const queryString = new URLSearchParams(window.location.search)
    const connectionFromQueryString = queryString.get('connection')
    const connectionFromSessionStorage = sessionStorage.getItem('connection')

    const resolvedConnection =
      connectionFromQueryString ||
      connectionFromSessionStorage ||
      defaultConnection

    sessionStorage.setItem('connection', resolvedConnection)
    setConnection(resolvedConnection)
  }, [])

  return (
    <>
      <Loader />
      {connection && (
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ''}
          clientId={process.env.REACT_APP_AUTH0_CLIENTID || ''}
          redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URL || ''}
          connection={connection}
          realm={connection}
        >
          <Main />
        </Auth0Provider>
      )}
      <Footer />
    </>
  )
}

export default App
