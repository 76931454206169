import styles from './FieldSet.module.css'
import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode[]
}

const FieldSet: React.FC<Props> = ({ children }) => {
  return <div className={styles.container}>{children}</div>
}

export default FieldSet
