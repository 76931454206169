import React from 'react'
import styles from '../Icon.module.css'

const Alert: React.FC = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0"
      width="48"
      height="48"
      rx="24"
      className={styles.redFill}
      fillOpacity="0.15"
    />
    <g clipPath="url(#clip0_5314_14744)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        className={styles.redFill}
        d="M23.9916 10.0107C31.7138 10.0107 37.9833 16.2781 37.9833
              23.9976C37.9833 31.7172 31.7138 37.9845 23.9916 37.9845C16.2694
              37.9845 10 31.7172 10 23.9976C10 16.2781 16.2695 10.0107 23.9916
              10.0107Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="white"
        d="M26.1977 29.4654C26.1977 28.2486 25.2098 27.2607 23.993
              27.2607H23.9903C22.7735 27.2607 21.7856 28.2486 21.7856
              29.4654C21.7856 30.6822 22.7735 31.6701 23.9903
              31.6701H23.993C25.2098 31.6701 26.1977 30.6822 26.1977 29.4654Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="white"
        d="M25.839 16.6947C25.839 16.4907 25.6734 16.3252 25.4695
              16.3252H22.5139C22.31 16.3252 22.1444 16.4907 22.1444
              16.6947V24.5235C22.1444 24.7274 22.31 24.893 22.513
              24.893H25.4695C25.6734 24.893 25.839 24.7274 25.839
              24.5235V16.6947Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_5314_14744">
        <rect
          width="28"
          height="28"
          fill="white"
          transform="translate(10 10)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Alert
