import styles from './Field.module.css'
import React from 'react'

interface Props {
  children: string
  label: string
}

const Field: React.FC<Props> = ({ label, children }) => {
  return (
    <div className={styles.container}>
      <label className={styles.label}>{label}</label>
      <br />
      <span>{children}</span>
    </div>
  )
}

export default Field
