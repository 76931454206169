import React from 'react'
import styles from './Button.module.css'

const Button = ({
  onClick,
  color,
  children,
}: {
  onClick: () => void
  color?: string
  children?: React.ReactNode
}) => (
  <button
    className={styles.container}
    onClick={() => onClick()}
    style={{ backgroundColor: color ? color : '' }}
  >
    {children}
  </button>
)

export default Button
