import styles from './Main.module.css'
import React, { useEffect } from 'react'
import Modal from '../Modal'
import { useAuth0 } from '@auth0/auth0-react'
import Icon from '../Icon'
import Heading from '../Heading'
import FieldSet from '../FieldSet'
import Field from '../Field'
import Button from '../Button'

const Main: React.FC = () => {
  const {
    user,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    // getAccessTokenSilently,
  } = useAuth0()
  const hasLoggedOut = JSON.parse(
    sessionStorage.getItem('hasLoggedOut') || 'false'
  )

  useEffect(() => {
    console.log('run useEffect')
    console.log('isLoading ' + isLoading)
    console.log('isAuthenticated ' + isAuthenticated)
    console.log('hasLoggedOut ' + hasLoggedOut)
    // TODO handle error
    if (!isLoading && !isAuthenticated && !hasLoggedOut) {
      console.log('redirect to login')
      loginWithRedirect()
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, hasLoggedOut])

  const data = {
    Sähköpostiosoite: user?.email,
    Nimi: `${user?.given_name} ${user?.family_name}`,
    statCode: user?.['https://almamedia.net/alma_statcode'],
    group: user?.['https://almamedia.net/alma_group'],
    endUser: user?.['https://almamedia.net/alma_enduser'],
  }

  return (
    <main className={styles.container}>
      {isAuthenticated && (
        <>
          <Modal>
            <Icon checkMarkCircle />
            <Heading
              level="3"
              style={{ marginBottom: '20px', fontSize: '22px' }}
            >
              Hei {user?.given_name}, <br />
              kirjautuminen onnistui
            </Heading>
            <span>Käyttäjätietosi ovat</span>
            <FieldSet>
              {Object.entries(data).map(({ 0: key, 1: value }) => (
                <Field key={key} label={key}>
                  {value || '-'}
                </Field>
              ))}
            </FieldSet>
            <div></div>
            <Button
              onClick={() => {
                console.log('do logout')
                console.log('set hasLoggedOut true sessionStorage')
                sessionStorage.setItem('hasLoggedOut', JSON.stringify(true))
                logout({
                  returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URL || '',
                })
              }}
            >
              Kirjaudu ulos
            </Button>
          </Modal>
        </>
      )}
      {!isAuthenticated && hasLoggedOut && (
        <Modal>
          <Icon checkMarkCircle />
          <Heading level="3" style={{ fontSize: '22px' }}>
            Olet nyt kirjautunut ulos
          </Heading>
          Voit sulkea tämän sivun.
        </Modal>
      )}
    </main>
  )
}

// const ErrorModal = () => (
//   <Modal width="450px">
//     <Icon alert />
//     <Heading level="3" style={{ color: 'red' }}>
//       Kirjautuminen ei onnistunut
//     </Heading>
//     Kirjautuminen ei onnistunut.
//   </Modal>
// )

export default Main
