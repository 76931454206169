import React from 'react'
import styles from './styles.module.scss'

const Footer = () => (
  <footer className={styles.footer}>
    <svg viewBox="0 0 48 48" fill="#eceef2" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.67989L0.00299859 0L22.5614 0.0449358L22.6364 22.6207L0 22.6177L0.0329845 8.45992L16.996 8.49287L16.954 5.63195L0 5.67989ZM16.987 16.9678L16.99 14.0739L5.66736 14.1099L5.68535 16.9588L16.987 16.9678Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3666 31.0592V25.3793L47.9251 25.4242L48 48L25.3636 47.997L25.3996 33.8392L42.3574 33.8721L42.3154 31.0112L25.3666 31.0592ZM42.3484 42.347L42.3544 39.4532L31.0332 39.4921L31.0512 42.3381L42.3484 42.347Z"
      />
      <path d="M31.0575 0.00803254L31.096 16.9638L48 16.9633L47.989 22.614L25.373 22.6207L25.3636 0L31.0575 0.00803254Z" />
      <path d="M22.6364 25.3793L22.5984 47.9657L17.0106 48L16.9181 30.9725H14.2118L14.1404 48H8.47197L8.4998 30.9725H5.74132L5.70336 48H0.0221685L0 25.3931L22.6364 25.3793Z" />
    </svg>
    <span>Tunnistautumisen testisivusto</span>
  </footer>
)

export default Footer
